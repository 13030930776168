<template>
  <div class="stateDiv" 
  v-show="box == ''? false:true"
  :style="{borderColor: color,color:color}">
  <img :src="icon" alt="问标网" class="tubiao" :style="{display:none}">
  <span class="word">{{box}}</span>
  </div>
</template>

<script>
// import Color from 'element-plus/es/components/color-picker/src/color'
import icon01 from '../imger/tranInfo/dui.png'
import icon02 from '../imger/tranInfo/chiming.png'
import icon03 from '../imger/tranInfo/xz.png'

export default {
  props:["word","none"],
  data(){
    return {
      box:this.word,
      imgno:this.none,
      color:'',
      icon:''

    }
  },
  watch:{
    word(curVal,oldVal){
      if(curVal!== 0){
        this.box = curVal
      } 
    },
    none(curVal,oldVal){
      if(curVal!== 0){
        this.imgno = curVal
      } 
    },
  },
  created(){
    this.word=="无效"? this.color='#999999' :
    this.word=="已注册"? this.color='#2DAA1D':
    this.word=="已过期"? this.color='#F0000F':
    this.word=="其他"? this.color='#4196F7':
    this.color='#EC7100'

    this.word=="申请中"? this.icon=icon02 :this.word=="已注册"? this.icon=icon01: this.icon=icon03
  },
  beforeUpdate(){
    this.word=="无效"? this.color='#999999' :
    this.word=="已注册"? this.color='#2DAA1D':
    this.word=="已过期"? this.color='#F0000F':
    this.word=="其他"? this.color='#4196F7':
    this.color='#EC7100'
    this.word=="申请中"? this.icon=icon02 :this.word=="已注册"? this.icon=icon01: this.icon=icon03
  }
}
</script>

<style scoped lang="scss">
.stateDiv {
  display: inline-flex;
  min-width: 3.125rem /* 50px -> 3.125rem */;
  height: 1.55625rem /* 24.9px -> 1.55625rem */;
  border-radius: 0.77875rem /* 12.46px -> 0.77875rem */;
  border: 0.0625rem /* 1px -> 0.0625rem */ solid;
  padding-left:0.5625rem /* 9px -> 0.5625rem */;
  padding-right: 0.5625rem /* 9px -> 0.5625rem */;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  .tubiao {
    width: 0.9375rem /* 15px -> 0.9375rem */;
    height: 0.9375rem /* 15px -> 0.9375rem */;
    margin-right: 0.5625rem /* 9px -> 0.5625rem */;
  }

}
</style>