<template>
  <div v-show="login" class="login01">
    <div class="signin"></div>
    <div class="signinBox">
      <div class="signinBoxTop">
        <!-- logo以及微信小程序二维码 -->
        <div class="logoPicture">
          <img
            src="../../imger/WBWlogo01.png"
            title="问标网"
            alt="问标网"
            class="signinicon01"
          />
          <img class="signinicon02" alt="问标网登陆二维码" />
          <p class="signinword01">微信扫码访问小程序</p>
        </div>

        <!-- 输入框的输入登录信息栏 -->
        <div class="loginInput">
          <p class="loginInputtitle">快捷登录</p>
          <input
            class="phone"
            type="text"
            placeholder="请输入手机号"
            v-model="phone"
          />
          <div class="passwordbox">
            <input
              class="password"
              type="text"
              placeholder="请输入验证码"
              v-model="code"
            />
            <el-button
              class="obtain"
              @click="obtaincode"
              :disabled="disabled"
              >{{ codetext }}</el-button
            >
          </div>
          <button class="login" @click="loging">
            <span class="loginWord">登录</span>
          </button>
        </div>
      </div>

      <!-- 下方的关闭按钮 -->
      <div class="signinBoxClose">
        <img
          src="../../imger/tranClassGroup/group111.png"
          alt="问标网"
          class="signinicon03"
          @click="close"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { sengOut, receive, newkeyCode } from "../../API/login";
import { userInfo } from "../../API/user";
export default {
  props: ["loginState"],
  inject: ["reload"], //注入App里的reload方法
  data() {
    return {
      login: this.loginState,
      phone: "",
      code: "",
      disabled: false, //决定获取验证码按钮是否禁用
      codetext: "获取验证码",
      totalTime: 60,
      success: "", //存放登录成功返回的数据
      openNum: 0, //websocket会话次数存储
      userdata: "",
      jiesu: null, //定时器
      timenumber: 1,
      ws:''
    };
  },

  watch: {
    loginState(curVal, oldVal) {
      if (curVal) {
        this.login = curVal;
        if (this.login == true) {
          let _this = this;
          // 建立连接
          _this.ws = new WebSocket(
            "wss://api.iwenbiao.com/user/api/v1/web/user/websocket_login"
          );

          // 发送消息
          _this.ws.onopen = function () {
            let newRoom_id = "";
            if (sessionStorage.getItem("room_id") !== null) {
              newRoom_id = sessionStorage.getItem("room_id");
            }
            let newsocket = {
              ip: _this.ip,
              key: sessionStorage.getItem("key"),
              platform: "问标",
              room_id: newRoom_id,
            };
            _this.openNum = 0; //onmessage的会话次数清零
            _this.ws.send(JSON.stringify(newsocket));
            // 定时重发
            _this.jiesu = setInterval(() => {
              if (_this.timenumber == 0) {
                let Room_id = "";
                if (sessionStorage.getItem("room_id") !== null) {
                  Room_id = sessionStorage.getItem("room_id");
                }
                let socket = {
                  ip: _this.ip,
                  key: sessionStorage.getItem("key"),
                  platform: "问标",
                  room_id: Room_id,
                };
                _this.openNum = 0; //onmessage的会话次数清零
                _this.ws.send(JSON.stringify(socket));
              }
              if (_this.timenumber == 179) {
                _this.timenumber = 0;
              } else {
                _this.timenumber++;
              }
            }, 1000);
          };

          // 接受消息
          _this.ws.onmessage = function (data) {
            _this.timenumber = 1;
            const image = JSON.parse(data.data);
            // 会话次数存储
            _this.openNum++;
            if (_this.openNum == 1) {
              document
                .querySelector(".signinicon02")
                .setAttribute("src", `data:image/jpeg;base64,${image.image}`);
              sessionStorage.setItem("room_id", image.room_id);
            }
            if (_this.openNum == 2) {
              if (image.status == "ok") {
                _this.timenumber = 1; //清空计时
              }
            }
            if (_this.openNum == 3) {
              _this.userdata = JSON.parse(image);
            }
          };
          // 断开链接时
          _this.ws.onclose = async function () {
            // 连开链接时经行重连
            if (
              _this.userdata.user_id == undefined ||
              _this.userdata.phone == undefined
            ) {
              ws = new WebSocket(
                "wss://api.iwenbiao.com/user/api/v1/web/user/websocket_login"
              );
              _this.timenumber = 1; //重连之后立刻进行发送消息回调函数
            } else {
              _this.openNum = 0; //次数清零
              // 获取userkey
              const reskey = await newkeyCode({
                key: sessionStorage.getItem("key"),
                userid: _this.userdata.user_id,
                phone: _this.userdata.phone,
              });
              if (reskey.msg == "success") {
                await sessionStorage.setItem("userkey", reskey.data);
                // 查看个人信息
                const res = await userInfo({
                  key: sessionStorage.getItem("userkey"),
                  phone: _this.userdata.phone,
                  user_id: _this.userdata.user_id,
                });
                if (res.data.key) {
                  await localStorage.setItem(
                    "user",
                    JSON.stringify(res.data.user_data)
                  );
                  await sessionStorage.setItem("userkey", res.data.key);
                }
                // 删除roomid
                await clearInterval(this.jiesu);
                await sessionStorage.removeItem("room_id");
                await _this.reload();
              }
            }
          };
        }
      }
    },
  },
  destroyed() {
    // 删除roomid
    sessionStorage.removeItem("room_id");
    clearInterval(this.jiesu);
    if (this.login == false) {
      this.ws.close();
    }
  },
  methods: {
    close() {
      clearInterval(this.jiesu);
      sessionStorage.removeItem("room_id");
      this.login = false;
      this.$emit("close", this.login);
      this.ws.close();
    },
    // 发送验证码
    async obtaincode() {
      if (this.phone) {
        if (
          /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/.test(
            this.phone
          )
        ) {
          //登录获取验证码请求
          const res = await sengOut({
            key: sessionStorage.getItem("key"),
            phone: this.phone,
          });
          if (res.data.key) {
            this.$message({
              message: "验证码发送成功，请注意查看短信。",
              type: "success",
            });
            await sessionStorage.setItem("key", res.data.key);
            //将获取验证码的按钮禁用并在倒计时结束之后重新启用
            this.disabled = true;
            this.codetext = this.totalTime + "s后重新发送";
            let clock = window.setInterval(() => {
              this.totalTime--;
              this.codetext = this.totalTime + "s后重新发送";
              if (this.totalTime < 0) {
                window.clearInterval(clock);
                this.codetext = "获取验证码";
                this.totalTime = 60;
                this.disabled = false;
              }
            }, 1000);
          }
        } else {
          this.$message({
            message: "您输入的手机号有误，请重新输入。",
            type: "error",
          });
        }
      } else {
        this.$message({
          message: "请输入手机号!",
          type: "warning",
        });
      }
    },
    // 校验验证码
    async loging() {
      if (this.phone && this.code) {
        //登录手机号验证码验证请求
        const res = await receive({
          key: sessionStorage.getItem("key"),
          code: this.code,
          phone: this.phone,
          platform: "问标",
        });
        await sessionStorage.setItem("key", res.data.key);
        await localStorage.setItem("user", JSON.stringify(res.data.user));
        if (res.data.key) {
          this.$message({
            message: "登录成功！",
            type: "success",
          });
          const reskey = await newkeyCode({
            key: sessionStorage.getItem("key"),
            userid: res.data.user.id,
            phone: res.data.user.phone,
          });
          if (reskey.data) {
            await sessionStorage.setItem("userkey", reskey.data);
            this.success = reskey.data;
            this.login = false;
            this.$emit("close", this.login);
            this.reload();
          }
        } else {
          this.$message({
            message: "验证码输入错误!",
            type: "error",
          });
        }
      } else {
        this.$message({
          message: "登录失败!",
          type: "error",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.login01 {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}
.signin {
  height: 100%;
  width: 100%;
  background: rgba($color: #000000, $alpha: 0.5);
  backdrop-filter: blur(4px) brightness(100%);
}
.signinBox {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 52rem /* 832px -> 52rem */;
  height: 31.25rem /* 500px -> 31.25rem */;
  display: flex;
  flex-direction: column;
  .signinBoxTop {
    width: 100%;
    height: 24.5625rem /* 393px -> 24.5625rem */;
    display: flex;
    .logoPicture {
      width: 23.75rem /* 380px -> 23.75rem */;
      height: 100%;
      background-color: #eef7ff;
      border-top-left-radius: 0.625rem /* 10px -> 0.625rem */;
      border-bottom-left-radius: 0.625rem /* 10px -> 0.625rem */;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      .signinicon01 {
        width: 9.4375rem /* 151px -> 9.4375rem */;
      }
      .signinicon02 {
        width: 11.8125rem /* 189px -> 11.8125rem */;
        height: 11.8125rem /* 189px -> 11.8125rem */;
      }
      .signinword01 {
        font-size: 1.25rem /* 20px -> 1.25rem */;
        color: #333333;
      }
    }
    .loginInput {
      flex-grow: 1;
      background-color: #fff;
      border-top-right-radius: 0.625rem /* 10px -> 0.625rem */;
      border-bottom-right-radius: 0.625rem /* 10px -> 0.625rem */;
      padding-top: 1.5625rem /* 25px -> 1.5625rem */;
      padding-bottom: 1.5625rem /* 25px -> 1.5625rem */;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      .loginInputtitle {
        font-size: 1.125rem /* 18px -> 1.125rem */;
        color: #333333;
      }
      .phone {
        border: none;
        width: 23.3125rem /* 373px -> 23.3125rem */;
        height: 2.75rem /* 44px -> 2.75rem */;
        outline: none;
        border: 0.0625rem /* 1px -> 0.0625rem */ solid #e5e5e5;
        padding-left: 1.25rem /* 20px -> 1.25rem */;
        padding-right: 1.25rem /* 20px -> 1.25rem */;
        box-sizing: border-box;
      }
      .passwordbox {
        width: 23.3125rem /* 373px -> 23.3125rem */;
        height: 2.75rem /* 44px -> 2.75rem */;
        border: 0.0625rem /* 1px -> 0.0625rem */ solid #e5e5e5;
        display: flex;
        .password {
          width: 16.875rem /* 270px -> 16.875rem */;
          height: 100%;
          padding-left: 1.25rem /* 20px -> 1.25rem */;
          padding-right: 1.25rem /* 20px -> 1.25rem */;
          box-sizing: border-box;
          outline: none;
          border: none;
          border-right: 0.0625rem /* 1px -> 0.0625rem */ solid #e5e5e5;
        }
        .obtain {
          width: 9.375rem /* 150px -> 9.375rem */;
          flex-grow: 1;
          border: none;
          background-color: #fff;
        }
      }
      .login {
        width: 23.25rem /* 372px -> 23.25rem */;
        height: 2.75rem /* 44px -> 2.75rem */;
        background: #4d89f1;
        border-radius: 0.3125rem /* 5px -> 0.3125rem */;
        border: none;
        outline: none;
        .loginWord {
          font-size: 1.25rem /* 20px -> 1.25rem */;
          color: #fff;
        }
      }
    }
  }
  .signinBoxClose {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    .signinicon03 {
      width: 2.125rem /* 34px -> 2.125rem */;
      height: 2.125rem /* 34px -> 2.125rem */;
    }
  }
}
</style>
